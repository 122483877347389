/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useAuthContext } from '../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useSharedContext } from '../../../../../context/shared.context'
import {
	useFetchAutoPopulateLov,
	useLoadWorkflowRef,
} from '../../../../../hooks/form.hooks'
import { smartFormConst } from '../../../../../utils/action.constant'
import ParticipantsView from './ParticipantsView'
import {
	getMessageUniqueId,
	toSnakeCase,
} from '../../../../../utils/common.util'
import { customSelectComponents } from './optionFormatAsync'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useDisclosure } from '@chakra-ui/react'
import {
	loadFinalValue,
	prepareFinalFieldValueForEdit,
} from '../../../../../utils/form.util'
/**
 *  dataTypeID : 57
 *  dataTypeName : Workflow Reference
 */
const WorkflowReference = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	fieldIndex,
	scrollToBottom = () => {},
	fieldList,
	onPrevious,
}) => {
	const { mutate, data } = useLoadWorkflowRef()
	const {
		field_inline_data,
		field_id,
		form_id,
		field_reference_id,
		field_name,
		data_type_id,
		data_type_category_id,
	} = field
	const {
		state: { fields: formFields, isModelOpen },
		dispatch,
	} = useSmartFormContext()
	const { mutateAsync: loadPrefillFieldAsync } = useFetchAutoPopulateLov()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	// Object.keys(fields).forEach((key, index) => {
	// 	const fieldName = fields[key]?.field_value || ''
	// 	fieldValueObject[`filter_id_${index + 1}`] = fieldName?.value //key
	// })
	// console.log(fieldValueObject, 'fieldValueObject')
	const {
		state: { locale },
	} = useLanguageContext()

	const {
		state: { authData },
	} = useAuthContext()

	const {
		state: { onFlyDataId, onFlyAddData, onFlyFieldId },
	} = useSharedContext()

	const [searchValue, setSearchValue] = useState('')
	const [pageLimit, setPageLimit] = useState(10)
	const [searchResult, setSearchResult] = useState([])

	const { isOpen, onOpen, onClose } = useDisclosure()
	const onMountValidate = value => {
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
	}

	/**
	 * The function `handleDependentFields` checks if the current field is a dependent field of some other field.
	 * If so, prefillField is called for the dependent fields, and the value is dispatched.
	 */
	const handleDependentFields = selectedActivity => {
		fieldList?.forEach(item => {
			if (parseInt(item?.field_inline_data?.dependent_field_id) === field_id) {
				if (item.field_value_prefill_enabled === 1) {
					if (!!selectedActivity?.details?.activity_id) {
						loadAndDispatchPrefillField(item, selectedActivity)
					}
				}
			}
		})
	}

	const loadAndDispatchPrefillField = async (
		dependentField,
		selectedActivity
	) => {
		const res = await loadPrefillFieldAsync({
			form_id: dependentField.form_id,
			field_id: dependentField.field_id,
			dependent_workflow_activity_id: selectedActivity?.details?.activity_id,
			entity_id: 0,
		})
		let fieldValue
		if (!!res && res.length) {
			fieldValue = {
				...dependentField,
				field_data_type_id: dependentField.data_type_id,
				field_value: res,
			}
		} else {
			fieldValue = {
				...dependentField,
				field_data_type_id: dependentField.data_type_id,
				field_value: '',
			}
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: dependentField.field_id,
			formId: dependentField.form_id,
			value: loadFinalValue(
				dependentField,
				prepareFinalFieldValueForEdit(fieldValue, dependentField)
			),
		})
	}

	const handleChange = value => {
		handleDependentFields(value)
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}

		const data = value ? value.details : {}
		const workflowValue = !!data
			? {
					activity_id: !!data.activity_id ? data.activity_id : '',
					activity_title: !!data.activity_title ? data.activity_title : '',
					activity_cuid_1: !!data.activity_cuid_1 ? data.activity_cuid_1 : '',
					activity_cuid_2: !!data.activity_cuid_2 ? data.activity_cuid_2 : '',
					activity_cuid_3: !!data.activity_cuid_3 ? data.activity_cuid_3 : '',
					operating_asset_first_name: !!data.operating_asset_first_name
						? data.operating_asset_first_name
						: '',
			  }
			: {}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					data_type_combo_id: 0,
					data_type_combo_value: '0',
					field_value: !!workflowValue
						? Object.values(workflowValue).join('|')
						: '',
					message_unique_id: getMessageUniqueId(),
				},
				field_value: value,
			},
		})
	}

	const onChange = value => {
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}

		const data = value ? value.details : {}
		const workflowValue = !!data
			? {
					activity_id: !!data.activity_id ? data.activity_id : '',
					activity_title: !!data.activity_title ? data.activity_title : '',
					activity_cuid_1: !!data.activity_cuid_1 ? data.activity_cuid_1 : '',
					activity_cuid_2: !!data.activity_cuid_2 ? data.activity_cuid_2 : '',
					activity_cuid_3: !!data.activity_cuid_3 ? data.activity_cuid_3 : '',
					operating_asset_first_name: !!data.operating_asset_first_name
						? data.operating_asset_first_name
						: '',
			  }
			: {}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: {
				final: {
					form_id,
					field_id,
					field_reference_id,
					field_name,
					field_data_type_id: data_type_id,
					field_data_type_category_id: data_type_category_id,
					data_type_combo_id: 0,
					data_type_combo_value: '0',
					field_value: !!workflowValue
						? Object.values(workflowValue).join('|')
						: '',
					message_unique_id: getMessageUniqueId(),
				},
				field_value: value,
			},
		})
	}
	const getFilterIdValue = (filterId, filterFlag) => {
		if (fields.hasOwnProperty(filterId)) {
			const fieldName = fields[filterId]?.final.field_name
			const fieldFinal = fields[filterId]?.final
			const field_data_type_id = fields[filterId]?.final.field_data_type_id

			if (filterFlag === 1) {
				if (field_data_type_id === 71) {
					let jsondata = JSON.parse(fieldFinal?.field_value)

					return jsondata?.product_activity_type_name
				}
				return fieldFinal?.field_value
			} else {
				switch (fieldName) {
					case 'Product':
						return fieldFinal?.field_value
					case 'Product Cart':
						let jsondata = JSON.parse(fieldFinal?.field_value)
						return jsondata?.product_activity_type_name
					case 'Segment':
						return authData?.workforce_name
					case 'Circle':
						return authData?.account_name
					default:
						return null
				}
			}
		}

		return null
	}

	const buildWorkflowsRequestParams = (query = '', page_start = 0) => {
		let activityTypeId = 0
		let tagId = 0
		let tagTypeId = 0
		let isParticipant = 0
		let activityStatusTypeId = 0
		let activityStatusId = 0
		let flagDependent = 0
		let dependentFieldId = 0
		let dependentFieldServiceName = ''
		let activity_type_categoryId = 0
		let assetReferenceFieldId = ''

		if (
			!!field_inline_data &&
			Object.keys(field_inline_data).length > 0 &&
			field_inline_data.workflow_reference_restriction
		) {
			const {
				activity_type_id,
				tag_id,
				tag_type_id,
				flag_participating,
				activity_status_type_id,
				flag_dependent,
				dependent_field_id,
				dependent_field_service_param_name,
				activity_type_category_id,
				asset_reference_field_id,
				activity_status_id,
			} = field_inline_data.workflow_reference_restriction
			activityTypeId = activity_type_id || 0
			activity_type_categoryId = activity_type_category_id || 0
			tagId = tag_id || 0
			tagTypeId = tag_type_id || 0
			isParticipant = flag_participating || 0
			activityStatusTypeId = activity_status_type_id || 0
			activityStatusId = activity_status_id || 0
			flagDependent = flag_dependent || 0
			dependentFieldId = dependent_field_id || 0
			dependentFieldServiceName = dependent_field_service_param_name || ''
			assetReferenceFieldId = asset_reference_field_id || ''
		}
		let refAssetId = ''
		if (assetReferenceFieldId) {
			refAssetId = fields[assetReferenceFieldId]?.field_value.details.asset_id
		}

		let params = {
			search_string: query,
			flag_status: 0,
			flag_participating: isParticipant,
			activity_type_category_id: activity_type_categoryId,
			activity_type_id: activityTypeId,
			tag_id: tagId,
			tag_type_id: tagTypeId,
			activity_status_type_id: activityStatusTypeId,
			activity_status_id: activityStatusId,
			page_start: page_start,
			page_limit: 10,
			reference_asset_id: refAssetId,
		}
		if (isParticipant === 8) {
			// const filterFlags = [
			// 	field_inline_data?.workflow_reference_restriction?.filter_flag_1,
			// 	field_inline_data?.workflow_reference_restriction?.filter_flag_2,
			// 	field_inline_data?.workflow_reference_restriction?.filter_flag_3,
			// ]

			// const filterFieldIds = [
			// 	field_inline_data?.workflow_reference_restriction?.filter_field_id_1,
			// 	field_inline_data?.workflow_reference_restriction?.filter_field_id_2,
			// 	field_inline_data?.workflow_reference_restriction?.filter_field_id_3,
			// ]

			// const fieldValueObject = {}

			// for (let i = 0; i < filterFlags.length; i++) {
			// 	const filterId = filterFieldIds[i]
			// 	const filterFlag = filterFlags[i]
			// 	fieldValueObject[`filter_value_${i + 1}`] = getFilterIdValue(
			// 		filterId,
			// 		filterFlag
			// 	)
			// }

			let filter_flag_1 =
				field_inline_data?.workflow_reference_restriction?.filter_flag_1
			let filter_flag_2 =
				field_inline_data?.workflow_reference_restriction?.filter_flag_2
			let filter_flag_3 =
				field_inline_data?.workflow_reference_restriction?.filter_flag_3
			let filter_field_id_1 =
				field_inline_data?.workflow_reference_restriction?.filter_field_id_1
			let filter_field_id_2 =
				field_inline_data?.workflow_reference_restriction?.filter_field_id_2
			let filter_field_id_3 =
				field_inline_data?.workflow_reference_restriction?.filter_field_id_3
			let filter_field_id1_value =
				filter_flag_1 === 1
					? getFilterIdValue(filter_field_id_1, filter_flag_1)
					: authData?.account_name
			let filter_field_id2_value =
				filter_flag_2 === 1
					? getFilterIdValue(filter_field_id_2, filter_flag_2)
					: authData?.workforce_tag_name
			let filter_field_id3_value = getFilterIdValue(
				filter_field_id_3,
				filter_flag_3
			)
			let fieldValueObject = {}

			fieldValueObject = {
				filter_value_1: filter_field_id1_value,
				filter_value_2: filter_field_id2_value,
				filter_value_3: filter_field_id3_value,
			}
			params = {
				...params,
				...fieldValueObject,
				entity_id: 0,
			}
		}
		if (flagDependent && !!fields[dependentFieldId]) {
			params = {
				...params,

				flag_dependent: flagDependent,
				isElasticSearchEnabled:
					authData.organization_flag_elasticsearch_enabled,
				[`${dependentFieldServiceName}`]:
					fields[dependentFieldId].field_value.details.activity_id,
			}
		}
		return params
	}

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange(fields[field.field_id].field_value)
		}
	}, [])

	useEffect(() => {
		if (
			!!onFlyDataId &&
			onFlyDataId === 57 &&
			!!onFlyAddData &&
			!!onFlyAddData?.activity_id &&
			onFlyFieldId === field.field_id
		) {
			onChange({
				label: onFlyAddData.activity_title,
				value: onFlyAddData.activity_id,
				details: onFlyAddData,
			})
		}
	}, [onFlyDataId, onFlyAddData, onFlyFieldId])

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onMountValidate(fields[field.field_id].field_value)
		}
	}, [formFields])

	const loadService = async (e, page_start) => {
		return await new Promise(resolve => {
			mutate(
				{
					isElasticSearchEnabled:
						authData.organization_flag_elasticsearch_enabled,
					...buildWorkflowsRequestParams(e, page_start),
				},
				{
					onSuccess: async res => {
						setSearchResult(res)
						resolve(res)
					},
				}
			)
		})
	}
	const sleep = ms =>
		new Promise(resolve => {
			setTimeout(() => {
				resolve()
			}, ms)
		})
	const loadOptions = async (search, loadedOptions) => {
		let resp = await loadService(search, loadedOptions.length)
		let filtered = resp?.filter(i => {
			if (!!i.details && !!i.details.activity_cuid_3 && !!search) {
				return (
					i.label.toLowerCase().includes(search.toLowerCase()) ||
					i.details.activity_cuid_3.toLowerCase().includes(search.toLowerCase())
				)
			} else if (!!search) {
				return i.label.toLowerCase().includes(search.toLowerCase())
			} else {
				return i
			}
		})
		return {
			options: filtered,
			hasMore:
				filtered.length === 0
					? false
					: filtered.length % 10 === 0
					? true
					: false,
		}
	}

	return (
		<>
			<AsyncPaginate
				className={`workflow-reference-field ${toSnakeCase(field.field_name)}`}
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}`}
				components={customSelectComponents}
				isDisabled={isDisabled}
				placeholder={locale['Smart search']}
				borderRadius='md'
				size='sm'
				cacheOptions
				defaultOptions={searchResult}
				loadOptions={loadOptions}
				onChange={handleChange}
				value={fields[field.field_id] ? fields[field.field_id].field_value : {}}
				maxMenuHeight={350}
				minMenuHeight={350}
				onInputChange={e => scrollToBottom('select-scroll')}
				onFocus={e => scrollToBottom('select-scroll')}
				// menuPlacement={
				//   field.next_field_id === -1 ? 'top' : fieldIndex > 2 ? 'top' : 'bottom'
				// }
			/>

			{isModelOpen && <ParticipantsView />}
		</>
	)
}

export default WorkflowReference
