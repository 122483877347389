/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  List,
  ListItem,
  Text,
  Tooltip,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
  useLoadActivityParticipant,
  useLoadWorkflowParticipant,
} from '../../../../../hooks/activity.hooks'
import { useLanguageContext } from '../../../../../context/locale.context'

const ParticipantsView = ({ isOpen }) => {
  const {
    state: { isModelOpen, modelActivityId },
    dispatch,
  } = useSmartFormContext()
  const {
    state: { locale },
  } = useLanguageContext()

  const {
    activity_id,
    activity_lead_asset_id: lead,
    activity_title,
  } = modelActivityId
  const [participantPayload, setParticipantPayload] = useState({})
  const { mutate } = useLoadActivityParticipant()
  useEffect(() => {
    mutate(
      { activity_id: activity_id },
      {
        onSuccess: async data => {
          let activeOwners = data
            ? (data.list || []).filter(u => !!u.asset_flag_is_owner)
            : []
          let activeExternals = data
            ? (data.list || []).filter(u => u.asset_type_category_id === 45)
            : []
          let activeInternals = data
            ? (data.list || []).filter(u => u.asset_type_category_id) //Should fetch all participants
            : []
          let viewers = data
            ? (data.list || []).filter(
                u => u.asset_participant_access_id === 152
              )
            : []
          setParticipantPayload({
            activeOwners,
            activeExternals,
            activeInternals,
            viewers,
          })
          // console.log(['activeInternals', activeInternals])
          // console.log(['activeOwners', activeOwners])
          // console.log(['activeExternals', activeExternals])
          // let activepId = data ? (data.list || []).map(u => u.asset_id) : []
          // setActiveParticipants(activepId)
        },
      }
    )
  }, [])
  return (
    <>
      <Modal
        isOpen={isModelOpen}
        onClose={() => {
          dispatch({ type: smartFormConst.ON_MODEL_CLOSE })
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{activity_title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="auto">
            <VStack h="full">
              <Box bg="white" w="100%" my={4} py={2} textAlign="center">
                <Tabs isLazy>
                  <TabList>
                    {participantPayload &&
                    participantPayload.activeInternals &&
                    participantPayload.activeInternals.length > 0 ? (
                      <Tab>{locale['All']}</Tab>
                    ) : null}
                    {participantPayload &&
                    participantPayload.activeOwners &&
                    participantPayload.activeOwners.length > 1 ? (
                      <Tab>{locale['Owners']}</Tab>
                    ) : null}
                    {participantPayload &&
                    participantPayload.activeExternals &&
                    participantPayload.activeExternals.length > 0 ? (
                      <Tab>{locale['External']}</Tab>
                    ) : null}
                    {participantPayload &&
                    participantPayload.viewers &&
                    participantPayload.viewers.length > 0 ? (
                      <Tab>{locale['Viewers']}</Tab>
                    ) : null}
                  </TabList>
                  <TabPanels>
                    {participantPayload &&
                    participantPayload.activeInternals &&
                    participantPayload.activeInternals.length > 0 ? (
                      <TabPanel bg="white">
                        <List
                          px={5}
                          w="full"
                          spacing={3}
                          h="400px"
                          overflowY="scroll"
                        >
                          {(participantPayload.activeInternals || []).map(u => {
                            const {
                              account_name,
                              asset_flag_is_owner: isOwner,
                              operating_asset_first_name: fName,
                              operating_asset_phone_number: pCode,
                              operating_asset_phone_country_code: cCode,
                              workforce_name,
                              contact_email_id,
                              activity_creator_asset_id,
                              asset_id,
                              asset_type_name,
                            } = u
                            let isCreator =
                              activity_creator_asset_id === asset_id
                            let islead = lead === asset_id
                            return (
                              <>
                                <ListItem>
                                  <Flex spacing={2} alignItems="center">
                                    <Center
                                      position="relative"
                                      alignSelf="center"
                                    >
                                      <Avatar
                                        name={fName}
                                        color={!!islead ? 'white' : 'black.400'}
                                        bg={
                                          !!islead ? 'brand.800' : 'secondary'
                                        }
                                        size="md"
                                      />
                                    </Center>
                                    <VStack
                                      ml={2}
                                      flex={1}
                                      alignItems="flex-start"
                                    >
                                      <Text
                                        color="brand.800"
                                        fontSize="14px"
                                        textTransform="capitalize"
                                      >
                                        {fName}
                                        {!!isOwner && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Owner']})
                                          </Text>
                                        )}
                                        {!!isCreator && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Creator']})
                                          </Text>
                                        )}
                                        {lead === asset_id && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Lead']})
                                          </Text>
                                        )}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {asset_type_name}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {`+${cCode} ${pCode}`} |{' '}
                                        {contact_email_id}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {workforce_name} | {account_name}
                                      </Text>
                                    </VStack>
                                  </Flex>
                                </ListItem>
                                <Divider border="1px" borderColor="secondary" />
                              </>
                            )
                          })}
                        </List>
                      </TabPanel>
                    ) : null}

                    {participantPayload &&
                    participantPayload.activeOwners &&
                    participantPayload.activeOwners.length > 1 ? (
                      <TabPanel bg="white">
                        <List
                          px={5}
                          w="full"
                          spacing={3}
                          h="400px"
                          overflowY="scroll"
                        >
                          {(participantPayload.activeOwners || []).map(u => {
                            const {
                              account_name,
                              asset_flag_is_owner: isOwner,
                              operating_asset_first_name: fName,
                              operating_asset_phone_number: pCode,
                              operating_asset_phone_country_code: cCode,
                              workforce_name,
                              activity_creator_asset_id,
                              contact_email_id,
                              asset_id,
                              asset_type_name,
                            } = u
                            let isCreator =
                              activity_creator_asset_id === asset_id
                            let islead = lead === asset_id

                            return (
                              <>
                                <ListItem>
                                  <Flex spacing={2} alignItems="center">
                                    <Center
                                      position="relative"
                                      alignSelf="center"
                                    >
                                      <Avatar
                                        name={fName}
                                        color={!!islead ? 'white' : 'black.400'}
                                        bg={
                                          !!islead ? 'brand.800' : 'secondary'
                                        }
                                        size="md"
                                      />
                                    </Center>
                                    <VStack
                                      ml={2}
                                      flex={1}
                                      alignItems="flex-start"
                                    >
                                      <Text
                                        color="brand.800"
                                        fontSize="14px"
                                        textTransform="capitalize"
                                      >
                                        {fName}
                                        {!!isOwner && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Owner']})
                                          </Text>
                                        )}
                                        {!!isCreator && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Creator']})
                                          </Text>
                                        )}
                                        {lead === asset_id && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Lead']})
                                          </Text>
                                        )}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {asset_type_name}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {`+${cCode} ${pCode}`} |{' '}
                                        {contact_email_id}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {workforce_name} | {account_name}
                                      </Text>
                                    </VStack>
                                  </Flex>
                                </ListItem>
                                <Divider border="1px" borderColor="secondary" />
                              </>
                            )
                          })}
                        </List>
                      </TabPanel>
                    ) : null}
                    {participantPayload &&
                    participantPayload.activeExternals &&
                    participantPayload.activeExternals.length > 0 ? (
                      <TabPanel bg="white">
                        <List
                          px={5}
                          w="full"
                          spacing={3}
                          h="400px"
                          overflowY="scroll"
                        >
                          {(participantPayload.activeExternals || []).map(u => {
                            const {
                              account_name,
                              asset_flag_is_owner: isOwner,
                              operating_asset_first_name: fName,
                              operating_asset_phone_number: pCode,
                              operating_asset_phone_country_code: cCode,
                              workforce_name,
                              activity_creator_asset_id,
                              asset_id,
                              contact_email_id,
                              asset_type_name,
                            } = u
                            let isCreator =
                              activity_creator_asset_id === asset_id
                            let islead = lead === asset_id

                            return (
                              <>
                                <ListItem>
                                  <Flex spacing={2} alignItems="center">
                                    <Center
                                      position="relative"
                                      alignSelf="center"
                                    >
                                      <Avatar
                                        name={fName}
                                        color={!!islead ? 'white' : 'black.400'}
                                        bg={
                                          !!islead ? 'brand.800' : 'secondary'
                                        }
                                        size="md"
                                      />
                                    </Center>
                                    <VStack
                                      ml={2}
                                      flex={1}
                                      alignItems="flex-start"
                                    >
                                      <Text
                                        color="brand.800"
                                        fontSize="14px"
                                        textTransform="capitalize"
                                      >
                                        {fName}
                                        {!!isOwner && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Owner']})
                                          </Text>
                                        )}
                                        {!!isCreator && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Creator']})
                                          </Text>
                                        )}
                                        {lead === asset_id && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Lead']})
                                          </Text>
                                        )}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {asset_type_name}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {`+${cCode} ${pCode}`} |{' '}
                                        {contact_email_id}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {workforce_name} | {account_name}
                                      </Text>
                                    </VStack>
                                  </Flex>
                                </ListItem>
                                <Divider border="1px" borderColor="secondary" />
                              </>
                            )
                          })}
                        </List>
                      </TabPanel>
                    ) : null}

                    {participantPayload &&
                    participantPayload.viewers &&
                    participantPayload.viewers.length > 0 ? (
                      <TabPanel bg="white">
                        <List
                          px={5}
                          w="full"
                          spacing={3}
                          h="400px"
                          overflowY="scroll"
                        >
                          {(participantPayload.viewers || []).map(u => {
                            const {
                              account_name,
                              asset_flag_is_owner: isOwner,
                              operating_asset_first_name: fName,
                              operating_asset_phone_number: pCode,
                              operating_asset_phone_country_code: cCode,
                              workforce_name,
                              activity_creator_asset_id,
                              contact_email_id,
                              asset_id,
                              asset_type_name,
                            } = u
                            let isCreator =
                              activity_creator_asset_id === asset_id
                            let islead = lead === asset_id

                            return (
                              <>
                                <ListItem>
                                  <Flex spacing={2} alignItems="center">
                                    <Center
                                      position="relative"
                                      alignSelf="center"
                                    >
                                      <Avatar
                                        name={fName}
                                        color={!!islead ? 'white' : 'black.400'}
                                        bg={
                                          !!islead ? 'brand.800' : 'secondary'
                                        }
                                        size="md"
                                      />
                                    </Center>
                                    <VStack
                                      ml={2}
                                      flex={1}
                                      alignItems="flex-start"
                                    >
                                      <Text
                                        color="brand.800"
                                        fontSize="14px"
                                        textTransform="capitalize"
                                      >
                                        {fName}
                                        {!!isOwner && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Owner']})
                                          </Text>
                                        )}
                                        {!!isCreator && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Creator']})
                                          </Text>
                                        )}
                                        {lead === asset_id && (
                                          <Text
                                            as="span"
                                            color="brand.800"
                                            fontSize="14px"
                                            fontWeight="600"
                                            ml={2}
                                          >
                                            ({locale['Lead']})
                                          </Text>
                                        )}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {asset_type_name}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {`+${cCode} ${pCode}`} |{' '}
                                        {contact_email_id}
                                      </Text>
                                      <Text
                                        color="brand.800"
                                        fontSize="12px"
                                        textAlign="left"
                                      >
                                        {workforce_name} | {account_name}
                                      </Text>
                                    </VStack>
                                  </Flex>
                                </ListItem>
                                <Divider border="1px" borderColor="secondary" />
                              </>
                            )
                          })}
                        </List>
                      </TabPanel>
                    ) : null}
                  </TabPanels>
                </Tabs>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => dispatch({ type: smartFormConst.ON_MODEL_CLOSE })}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ParticipantsView
