import {
	Box,
	Divider,
	Text,
	IconButton,
	Flex,
	Icon,
	useStyles,
	useTheme,
} from '@chakra-ui/react'
import { useLanguageContext } from '../../../../../context/locale.context'
import { BsInfoCircle } from 'react-icons/bs'
import { smartFormConst } from '../../../../../utils/action.constant'
import { useSmartFormContext } from '../../../../../context/smart-form.context'

export const customSelectComponents = {
	Option: ({
		innerRef,
		innerProps,
		children,
		isFocused,
		isDisabled,
		data,
		selectProps: { size },
	}) => {
		const {
			state: { locale },
		} = useLanguageContext()
		const {
			state: { isModelOpen },
			dispatch,
		} = useSmartFormContext()
		const { item } = useStyles()
		const theme = useTheme()
		const {
			details: {
				activity_cuid_1,
				activity_cuid_2,
				activity_cuid_3,
				operating_asset_first_name,
			},
		} = data
		return (
			<Box
				role='button'
				borderRadius='sm'
				sx={{
					...item,
					w: '100%',
					textAlign: 'left',
					bg: isFocused ? theme.colors.gray[100] : 'transparent',
					cursor: 'pointer',
					fontSize: size,
					...(isDisabled && item._disabled),
				}}
				ref={innerRef}
				{...innerProps}
				{...(isDisabled && { disabled: true })}
			>
				<Flex justifyContent='space-between'>
					{' '}
					{children}
					<Box>
						{' '}
						<IconButton
							bg='white'
							aria-label='close'
							size='sm'
							borderRadius='md'
							zIndex='999999'
							boxShadow='md'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							icon={
								<Icon
									className={`field-item-info-icon`}
									as={BsInfoCircle}
									w={4}
									h={4}
								/>
							}
							onClick={e => {
								dispatch({
									type: smartFormConst.ON_MODEL_OPEN,
									activityId: data.details,
								})
								e.stopPropagation()
							}}
						/>
					</Box>
				</Flex>
				<Divider />
				{operating_asset_first_name ? (
					<Text>
						{locale['Creator']} :{' '}
						<Text fontWeight='500' as='span'>
							{operating_asset_first_name}
						</Text>
					</Text>
				) : null}
				{activity_cuid_1 ? (
					<Text>
						{locale['CUID 1']} :{' '}
						<Text fontWeight='500' as='span'>
							{activity_cuid_1}
						</Text>
					</Text>
				) : null}
				{activity_cuid_2 ? (
					<Text>
						{locale['CUID 2']} :{' '}
						<Text fontWeight='500' as='span'>
							{activity_cuid_2}
						</Text>
					</Text>
				) : null}
				{activity_cuid_3 ? (
					<Text>
						{locale['CUID 3']} :{' '}
						<Text fontWeight='500' as='span'>
							{activity_cuid_3}
						</Text>
					</Text>
				) : null}
			</Box>
		)
	},
}
