/* eslint-disable react-hooks/exhaustive-deps */
import {
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLanguageContext } from '../../../../../context/locale.context'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import {
	useLoadProductCategories,
	useLoadProductVariants,
	useLoadProductWorkflow,
	useLoadProductWorkflowTypes,
} from '../../../../../hooks/form.hooks'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
	getAddToCurrentDateUTC,
	getCurrentDateUTC,
} from '../../../../../utils/date.utils'
import {
	loadFinalValue,
	prepareFinalFieldValueForEdit,
} from '../../../../../utils/form.util'
import Select from '../../../../chakra-react-select'
import './style.scss'
/**
 * dataTypeID 71
 * dataTypeName  Product Cart
 *
 */
const ProductCartField = ({
	field,
	isRequired,
	isDisabled = true,
	onValidate,
	isBulkEdit,
	fieldIndex,
	...rest
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	// console.log(fields, 'fields');
	const { field_id } = field

	const { mutate: mutateProductCategories, data: productCategories } =
		useLoadProductCategories()
	const { mutate: mutateProductWorkflow, data: productWorkflows } =
		useLoadProductWorkflow()
	const { mutate: mutateProductWorkflowTypes, data: productWorkflowTypes } =
		useLoadProductWorkflowTypes()
	const { mutate: mutateProductVariants, data: productVariants } =
		useLoadProductVariants()

	const [fromEdit, setFromEdit] = useState(false)
	const [isfromEdit, setIsFromEdit] = useState(false)
	const [productCat, setproductCat] = useState(null)
	const [productWorkflowType, setproductWorkflowType] = useState(null)
	const [productWorkflow, setproductWorkflow] = useState(null)
	const [productVariant, setproductVariant] = useState([])
	const [productTotal, setProductTotal] = useState(0)
	const [inline_data, set_inline_data] = useState({})
	const [BottleTotal, setBottleTotal] = useState([])
	const [caseTotal, setCaseTotal] = useState([])
	const [totalQuantity, setTotalQuantity] = useState([])

	// useEffect(() => {
	//   if (!!productCatData) {
	//     setproductCat(productCatData);
	//   }
	//   if (!!productWorkflowData) {
	//     setproductWorkflow(productWorkflowData);
	//   }
	//   if (!!productWorkflowTypeData) {
	//     setproductWorkflowType(productWorkflowTypeData);
	//   }
	//   if (!!productVariantData) {
	//     setproductVariant(productVariantData);
	//   }
	//   if (!!productTotalData) {
	//     setProductTotal(productTotalData);
	//   }
	// });

	useEffect(() => {
		mutateProductCategories({})
	}, [mutateProductCategories])

	useEffect(() => {
		const {
			field_inline_data: { product_cart_config = {} },
		} = field
		set_inline_data(product_cart_config)
	}, [field])

	useEffect(() => {
		if (
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			const {
				productCatData,
				productWorkflowData,
				productWorkflowTypeData,
				productVariantData,
				productTotalData,
				isFromEdit,
			} = fields[field_id].field_value
			const {
				total_double_quantity_lable_2,
				total_double_quantity_lable_1,
				cart_total_quantity,
			} = JSON.parse(fields[field_id].final.field_value)

			if (!!productCatData && isFromEdit) {
				setFromEdit(isFromEdit)
				setIsFromEdit(isFromEdit)
				setproductCat(productCatData)
				setproductWorkflowType(productWorkflowTypeData)
				setproductWorkflow(productWorkflowData)
				setproductVariant(productVariantData)
				setProductTotal(productTotalData)
				setBottleTotal(total_double_quantity_lable_2)
				setCaseTotal(total_double_quantity_lable_1)
				setTotalQuantity(cart_total_quantity)
				onValidate(true)
				const { value } = productWorkflowData
				mutateProductVariants({
					parent_activity_id: value,
					flag: 1,
					sort_flag: 1,
					datetime_start: getCurrentDateUTC(),
					datetime_end: getAddToCurrentDateUTC(),
					start_from: 0,
					limit_value: 50,
				})
			}
		}
	}, [isfromEdit])

	useEffect(() => {
		if (
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			const {
				productCatData,
				productWorkflowData,
				productWorkflowTypeData,
				productVariantData,
				productTotalData,
				isFromEdit,
			} = fields[field_id].field_value

			const {
				total_double_quantity_lable_2,
				total_double_quantity_lable_1,
				cart_total_quantity,
			} = JSON.parse(fields[field_id].final.field_value)
			if (!!productCatData && isFromEdit) {
				setFromEdit(isFromEdit)
				setIsFromEdit(isFromEdit)
				setproductCat(productCatData)
				setproductWorkflowType(productWorkflowTypeData)
				setproductWorkflow(productWorkflowData)
				setproductVariant(productVariantData)
				setProductTotal(productTotalData)
				setBottleTotal(total_double_quantity_lable_2)
				setCaseTotal(total_double_quantity_lable_1)
				setTotalQuantity(cart_total_quantity)
				onValidate(true)
			}
		}
	}, [fields[field_id]])

	useEffect(() => {
		if (!!productCat && !fromEdit) {
			onValidate(false)
			setproductWorkflowType(null)
			setproductWorkflow(null)
			setproductVariant([])
			mutateProductWorkflowTypes({
				tag_id: productCat.value,
			})
			mutateProductWorkflow({})
			mutateProductVariants({})
		}
	}, [productCat])

	useEffect(() => {
		if (!!productWorkflowType && !fromEdit) {
			onValidate(false)
			setproductWorkflow(null)
			setproductVariant([])
			const { details, value } = productWorkflowType
			const { activity_type_category_id, tag_id, tag_type_id } = details
			mutateProductWorkflow({
				activity_type_category_id,
				activity_type_id: value,
				flag: 0,
				tag_id,
				tag_type_id,
				search_string: '',
				flag_status: 0,
				flag_participating: 2,
				start_from: 0,
				limit_value: 50,
			})
			mutateProductVariants({})
		}
	}, [productWorkflowType])

	useEffect(() => {
		if (!!productWorkflow && !fromEdit) {
			onValidate(false)
			setproductVariant([])
			const { details, value } = productWorkflow
			const {
				activity_datetime_start_expected,
				activity_datetime_end_deferred,
			} = details
			mutateProductVariants({
				parent_activity_id: value,
				flag: 1,
				sort_flag: 1,
				datetime_start: getCurrentDateUTC(activity_datetime_start_expected),
				datetime_end: getAddToCurrentDateUTC(activity_datetime_end_deferred),
				start_from: 0,
				limit_value: 50,
			})
		} else if (inline_data?.flag_enable_double_quantity_calculation === 1) {
			mutateProductVariants({
				parent_activity_id: 0,
				flag: 2,
				sort_flag: 1,
				// datetime_start: getCurrentDateUTC(activity_datetime_start_expected),
				// datetime_end: getAddToCurrentDateUTC(activity_datetime_end_deferred),
				start_from: 0,
				limit_value: 50,
			})
		}
	}, [productWorkflow, inline_data])

	useEffect(() => {
		if (productVariant && productVariant.length > 0 && !fromEdit) {
			let total = productVariant.reduce(
				(s, f) => (!!f.productPrice ? s + Number(f.productPrice) : s + 0),
				0
			)
			let total_quantity = productVariant.reduce(
				(s, f) => (!!f.quantity ? s + Number(f.quantity) : s + 0),
				0
			)
			setProductTotal(total)
			setBottleTotal(Math.floor(Number(total_quantity) % 24))
			setCaseTotal(Math.floor(Number(total_quantity) / 24))
			setTotalQuantity(Number(total_quantity))
		} else if (!fromEdit) {
			setProductTotal(null)
			setBottleTotal(null)
			setCaseTotal(null)
			onValidate(false)
		}
	}, [productVariant])

	useEffect(() => {
		const isVarSet = !!productVariant.map(i => i.quantity).filter(Boolean)
			.length
		if (
			productTotal > -1 &&
			isVarSet &&
			productVariant.length > 0 &&
			!fromEdit
		) {
			onValidate(true)
			prepareFinalFieldValueForEdit(
				loadFinalValue(field, {
					productVariant,
					productTotal,
					BottleTotal,
					caseTotal,
					totalQuantity,
					productWorkflow,
					productCat,
					productWorkflowType,
				}).final
			)
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(field, {
					productVariant,
					productTotal,
					BottleTotal,
					caseTotal,
					totalQuantity,
					productWorkflow,
					productCat,
					productWorkflowType,
				}),
			})
			dispatch({
				type: smartFormConst.TO_REFRESH_NUM_VAL,
			})
		} else if (!fromEdit) {
			onValidate(false)
		}
	}, [productTotal, productVariant])

	const onPriceChange = (e, option) => {
		let variants = [...productVariant]

		let vPrice = e.target.value * Number(option.quantity)
		let vIndex = productVariant.findIndex(
			v => Number(v.value) === Number(option.value)
		)
		variants[vIndex].details.activity_cuid_3 = e.target.value
		variants[vIndex] = {
			...variants[vIndex],
			productPrice: vPrice,
			quantity: option.quantity,
		}
		setFromEdit(false)
		setproductVariant(variants)
	}

	const onQntyChange = (e, option) => {
		let variants = [...productVariant]
		let variantPrice = e.target.value * Number(option.details.activity_cuid_3)
		let vIndex = productVariant.findIndex(
			v => Number(v.value) === Number(option.value)
		)
		if (vIndex > -1) {
			variants[vIndex] = {
				...variants[vIndex],
				productPrice: variantPrice,
				quantity: e.target.value,
			}
		} else {
			variants.push({
				...option,
				productPrice: variantPrice,
				quantity: e.target.value,
			})
		}
		setFromEdit(false)
		setproductVariant(variants)
	}

	const onQnty1Change = (e, option, limit) => {
		let qty2 = option?.quantity_2 || 0
		let variants = [...productVariant]
		let quantity = Number(e.target.value) * Number(limit) + Number(qty2)
		let variantPrice = Number(quantity) * Number(option.details.activity_cuid_3)

		let vIndex = productVariant.findIndex(
			v => Number(v.value) === Number(option.value)
		)
		if (vIndex > -1) {
			variants[vIndex] = {
				...variants[vIndex],
				productPrice: variantPrice,
				quantity_1: Number(e.target.value),
				quantity: Number(quantity),
			}
		} else {
			variants.push({
				...option,
				productPrice: variantPrice,
				quantity_1: Number(e.target.value),
				quantity: Number(quantity),
			})
		}
		setFromEdit(false)
		setproductVariant(variants)
	}

	const onQnty2Change = (e, option, limit) => {
		let qty1 = option?.quantity_1 || 0
		let val =
			Number(e.target.value) < limit ? Number(e.target.value) : limit - 1
		let variants = [...productVariant]
		let quantity = Number(qty1) * limit + val
		let variantPrice = quantity * Number(option.details.activity_cuid_3)
		let vIndex = productVariant.findIndex(
			v => Number(v.value) === Number(option.value)
		)

		if (vIndex > -1) {
			variants[vIndex] = {
				...variants[vIndex],
				productPrice: variantPrice,
				quantity_2: val,
				quantity: Number(quantity),
			}
		} else {
			variants.push({
				...option,
				productPrice: variantPrice,
				quantity_2: val,
				quantity: Number(quantity),
			})
		}
		setFromEdit(false)
		setproductVariant(variants)
	}

	return (
		<VStack spacing={2} w='full'>
			<Select
				className='full-width'
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}-category`}
				isDisabled={
					(isDisabled ||
						isfromEdit ||
						inline_data?.flag_disable_l1_filter === 1) &&
					!isBulkEdit
				}
				options={productCategories || []}
				placeholder='Select product category'
				borderRadius='md'
				size='sm'
				value={productCat}
				// menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
				onChange={e => {
					setproductCat(e)
					setFromEdit(false)
				}}
			/>
			<Select
				className='full-width'
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}-product-types`}
				isDisabled={
					(isDisabled ||
						isfromEdit ||
						inline_data?.flag_disable_l2_filter === 1) &&
					!isBulkEdit
				}
				options={productWorkflowTypes || []}
				placeholder='Select product types'
				borderRadius='md'
				size='sm'
				value={productWorkflowType}
				onChange={setproductWorkflowType}
				// menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
			/>
			<Select
				className='full-width'
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}-product`}
				isDisabled={
					(isDisabled ||
						isfromEdit ||
						inline_data?.flag_disable_l3_filter === 1) &&
					!isBulkEdit
				}
				options={productWorkflows || []}
				placeholder='Select product'
				borderRadius='md'
				size='sm'
				value={productWorkflow}
				onChange={setproductWorkflow}
				// menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
			/>
			<Select
				isMulti={true}
				className='full-width'
				name={field.field_id}
				id={`${field.field_name.split(' ').join('-')}-variants`}
				isDisabled={isDisabled}
				options={
					!!productWorkflow
						? productVariants || [
								{
									details: {
										activity_cuid_2: null,
										activity_cuid_3: null,
									},
									label: 'Custom variant',
									value: '',
								},
						  ]
						: inline_data?.flag_enable_double_quantity_calculation === 1
						? productVariants
						: []
				}
				// menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
				placeholder='Select product variants'
				borderRadius='md'
				size='sm'
				value={productVariant}
				onChange={e => {
					//setproductVariant(unionBy(e, productVariant, 'label'));
					setproductVariant(e)
					setFromEdit(false)
				}}
			/>
			{!!productVariant && productVariant.length > 0 ? (
				<Table w='full'>
					<Thead position={'relative'} zIndex={1}>
						<Tr>
							<Th>{locale['Variant Name']}</Th>
							{inline_data?.flag_enable_double_quantity_calculation === 1 ? (
								<>
									<Th>{inline_data?.double_quantity_lable_1}</Th>
									<Th>{inline_data?.double_quantity_lable_2}</Th>
								</>
							) : null}
							<Th>{locale['Quantity']}</Th>
							<Th>{locale['Monthly Price']}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{productVariant.map((p, index) => {
							return (
								<Tr key={index}>
									<Td>{p.label}</Td>
									{inline_data?.flag_enable_double_quantity_calculation ===
									1 ? (
										<>
											<Td>
												<Input
													size='sm'
													borderRadius='sm'
													type='number'
													min={0}
													value={p.quantity_1 ? p.quantity_1 : ''}
													onChange={e =>
														onQnty1Change(
															e,
															p,
															inline_data?.double_quantity_limit_value
														)
													}
													isDisabled={isDisabled}
												/>
											</Td>
											<Td>
												<Input
													size='sm'
													borderRadius='sm'
													type='number'
													min={0}
													max={inline_data?.double_quantity_limit_value}
													value={p.quantity_2 ? p.quantity_2 : ''}
													onChange={e =>
														onQnty2Change(
															e,
															p,
															inline_data?.double_quantity_limit_value
														)
													}
													isDisabled={isDisabled}
												/>
											</Td>
										</>
									) : null}
									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											type='number'
											min={0}
											value={p.quantity ? p.quantity : ''}
											onChange={e => onQntyChange(e, p)}
											isDisabled={
												isDisabled ||
												inline_data?.flag_enable_double_quantity_calculation ===
													1
											}
										/>
									</Td>
									{p.value === 0 ? (
										<Td>
											<Input
												size='sm'
												borderRadius='sm'
												type='number'
												min={0}
												value={p.details.activity_cuid_3}
												onChange={e => onPriceChange(e, p)}
												isDisabled={isDisabled}
											/>
										</Td>
									) : (
										<Td>{p.details.activity_cuid_3}</Td>
									)}
								</Tr>
							)
						})}
						<Tr>
							<Td>{locale['Total Cart Value']} </Td>

							<Td>
								<Input
									size='sm'
									borderRadius='sm'
									type='number'
									min={0}
									value={Math.floor(caseTotal)}
									//onChange={e => onQntyChange(e, p)}
									isDisabled={true}
								/>
							</Td>
							{inline_data?.flag_enable_double_quantity_calculation === 1 ? (
								<>
									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											type='number'
											min={0}
											value={Number(BottleTotal)}
											//onChange={e => onQntyChange(e, p)}
											isDisabled={true}
										/>
									</Td>

									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											type='number'
											min={0}
											value={Number(totalQuantity)}
											//onChange={e => onQntyChange(e, p)}
											isDisabled={true}
										/>
									</Td>
								</>
							) : null}
							<Td>{Number(productTotal)}</Td>
						</Tr>
					</Tbody>
				</Table>
			) : null}
		</VStack>
	)
}

export default ProductCartField
